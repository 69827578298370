<template>
  <v-app>     
     <dashboard-Core-App-Bar />
    <dashboard-core-drawer />
    <dashboard-core-view />
    <dashboard-core-settings />
  </v-app>  
</template>

<script>
export default {
  name: "DashboardIndex",
  components: {   
    DashboardCoreAppBar: () => import("./components/user/AppBar"),
    DashboardCoreDrawer: () => import("./components/user/Drawer"), 
    DashboardCoreSettings: () => import("./components/user/Settings"),
    DashboardCoreView: () => import("./components/user/View"),
  },
  data: () => ({   
    loginuser: JSON.parse(sessionStorage.getItem('user')) || 0,   
  }),
    mounted() {
      let user = JSON.parse(sessionStorage.getItem('user')) || 0
      if (user.user_status == 'teacher' || user.user_status == 'se_director' || user.user_status == 'director' || user.user_status == 'S' || user.user_status == 'C' || user.user_status == "38" || user.user_status == "3801" || user.user_status == "3802" || user.user_status == "3803")
      {
      
      }else{
            this.$router.push('/')           
      }
        
    },
        methods: {        
      home() {
        this.$router.push('user')
      },
      logout() {
        sessionStorage.clear()
        this.$router.push('/')
      }
    }
};
</script>
<style>
.text_google {
  font-family: "Kanit", sans-serif;
}
.v-data-table thead th {
  font-size: 16px !important;
}
</style>
